window.alloy_all = window.alloy_all || {};
window.alloy_all.data = window.alloy_all.data || {};
window.alloy_all.data._adobe_corpnew = window.alloy_all.data._adobe_corpnew || {};
window.alloy_all.data._adobe_corpnew.digitalData = window.alloy_all.data._adobe_corpnew.digitalData || {};
window.alloy_all.data._adobe_corpnew.digitalData.page = window.alloy_all.data._adobe_corpnew.digitalData.page || {};
window.alloy_all.data._adobe_corpnew.digitalData.page.pageInfo =
  window.alloy_all.data._adobe_corpnew.digitalData.page.pageInfo || {};
window.alloy_all.data._adobe_corpnew.digitalData.page.pageInfo.language = 'en-US'; // language related to geo
window.marketingtech = {
  adobe: {
    launch: {
      url: process.env.LAUNCH_URL,
      controlPageLoad: true, // If pageload is controlled by page and Launch will wait for 10 seconds to listen a pageload call from page
    },
    alloy: {
      edgeConfigId: process.env.LAUNCH_EDGE_CONFIG_ID,
    },
    target: true,
    audienceManager: true,
  },
  sophia: false,
};
